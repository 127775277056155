import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Language,
} from "./styles";


const Footer = ({ t }: { t: TFunction }) => {
  return (
    <>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <Col lg={10} md={10} sm={12} xs={12}>
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            </Col>

            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Endereço")}</Language>
              <Para>Rua Almirante Barroso, 141 - Parque Estuario</Para>
              <Para>Guarujá - São Paulo</Para>
              <Para>+55 (13) 9 9703 - 0064</Para>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
